import React from 'react'

const Copyright = () => {
  return (
    <h4 className='copyright'>
      &copy; Victor Feitosa - 2018
    </h4>
  )
}

export default Copyright
